import React from 'react';
import classnames from 'classnames';

import { isServerSide, EMPTY_GIF } from '../lib/utils';

const loadingSupported = !isServerSide() && ('loading' in HTMLImageElement.prototype);

const LazyImage = ({ className, src, loading, alt, ...props }) => (
  <img
    {...props}
    className={classnames(className, loadingSupported ? undefined : 'lazyload')}
    alt={alt}
    src={loadingSupported ? src : EMPTY_GIF}
    data-src={loadingSupported ? undefined : src}
    loading={loading || "lazy"}
  />
);

export default LazyImage;
