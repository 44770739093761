import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import uniqueId from 'lodash/uniqueId';

import hostnames from '../../../config/settings/hostnames.yml';

const BASE_DOMAIN_REGEX = new RegExp(`${escapeRegExp(hostnames.base)}$`, 'i');

export const BASE_URL = `${hostnames.protocol}://${hostnames.primary}`;
export const EMPTY_GIF = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function sanitizeQuery(query) {
  return `${query}`
    .toLowerCase()
    .replaceAll(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
    .replaceAll(/\s{2,}/g, ' ')
    .trim()
}

export function slugify(str) {
  return `${str}`
    .toLowerCase()
    .trim()
    .replace(/[.:]+/g, '-')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

export function getAuthenticityToken() {
  const csrfTokenNode = typeof document !== 'undefined' ? document.querySelector('meta[name="csrf-token"]') : undefined;
  return csrfTokenNode ? csrfTokenNode.getAttribute('content') : undefined;
}

export function useSvgId(base) {
  const [id] = useState(uniqueId(base));
  return id;
}

export function useSharedRef(initialValue, refsToShare) {
  const innerRef = useRef(initialValue);
  const sharingRef = (value) => {
    innerRef.current = value;

    refsToShare.forEach((resolvableRef) => {
      if (typeof resolvableRef === 'function') {
        resolvableRef(value);
      } else {
        if (resolvableRef) {
          resolvableRef.current = value;
        }
      }
    });
  };

  if (!sharingRef.current) {
    Object.defineProperty(sharingRef, 'current', {
      get() {
        return innerRef.current;
      },
    });
  }

  return sharingRef;
}

function areInputsEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export function memoize(resultFn) {
  let lastThis;
  let lastArgs;
  let lastResult;
  let calledOnce = false;

  const result = (...newArgs) => {
    if (calledOnce && lastThis === this && areInputsEqual(newArgs, lastArgs)) {
      return lastResult;
    }

    lastResult = resultFn.apply(this, newArgs);
    calledOnce = true;
    lastThis = this;
    lastArgs = newArgs;
    return lastResult;
  };

  return result;
}

export function isServerSide() {
  try {
    return !(typeof document !== 'undefined');
  } catch(e) {
    return true;
  }
}

export function promiseScript(id, src, attributes = {}) {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
    }

    const tag = document.createElement('script');
    tag.id = id;
    tag.src = src;
    tag.async = true;
    tag.type = 'text/javascript';

    attributes['data-timestamp'] = `${new Date()}`;
    for (const a in attributes) {
      if (attributes.hasOwnProperty(a)) {
        tag.setAttribute(a, attributes[a]);
      }
    }

    tag.addEventListener('error', (err) => reject(err), false);
    tag.addEventListener('load', () => resolve(), false);

    document.getElementsByTagName('head')[0].appendChild(tag);
  });
}

export function getNextSibling(elem, selector) {
  let sibling = elem.nextElementSibling;

  if (!selector) {
    return sibling;
  }

  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }

    sibling = sibling.nextElementSibling;
  }
};

export function $N() {
  const len = arguments.length;

  for (let i = 0; i < len; i++) {
    if (arguments[i] !== null && arguments[i] !== undefined && !Number.isNaN(arguments[i])) {
      return arguments[i];
    }
  }

  return null;
}

export function inSameDomain(url) {
  if (url === undefined || url === null || url === '') {
    return false;
  } else if (typeof url === 'string' && url.match(/^\//)) {
    return true;
  } else if (url.hostname !== undefined) {
    return url.hostname.match(BASE_DOMAIN_REGEX) !== null;
  } else {
    const parser = document.createElement('a');
    parser.href  = url;

    return parser.hostname.match(BASE_DOMAIN_REGEX) !== null;
  }
}

export function mightBeEmail(s) {
  // There's no point trying to validate rfc822 fully, just look for ...@...
  return (/[^@]+@[^@]+/).test(s);
}

export function toTitleCase(s) {
  return startCase(toLower(trim(s)));
}

export function validateEmail(email) {
  if (isEmpty(email)) {
    return false;
  }

  const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
  return re.test(email);
}

export function validatePassword(password) {
  if (isEmpty(password)) {
    return false;
  }

  const re = new RegExp('^.{8,}$');
  return re.test(password);
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function objectToGetParams(object) {
  const query = Object.keys(object)
      .filter(key => !!object[key])
      .map(key => `${key}=${encodeURIComponent(object[key])}`)
      .join('&');

  return !isEmpty(query) ? `?${query}` : '';
}

export function ensureArray(object) {
  return Array.isArray(object) ? object : Array.of(object);
}

export function stringify(value) {
  return String(value);
}

export function countDecimals(value) {
  if (Number.isFinite(value)) {
    let e = 1;

    while (Math.round(value * e) / e !== value) {
      e *= 10;
    }

    return Math.log10(e);
  } else {
    return 0;
  }
}

export function maxPrecision(...values) {
  return Math.max(...values.map((v) => countDecimals(v)));
}

export function formatNumber(value, opts={}) {
  try {
    if (Number.isFinite(value)) {
      if (opts.precision) {
        opts.maximumFractionDigits = opts.precision;
        delete opts['precision'];
      }

      const formatter = new Intl.NumberFormat(opts.locale || [], opts);
      return formatter.format(value);
    } else {
      return '';
    }
  } catch(ex) {
    console.warn(ex);
    return value;
  }
}

export function arrayToOptions(array) {
  return array.map((v) => ({
    label: v,
    value: v,
  }));
}

const REGEXP_SCROLL_PARENT = /^(visible|hidden)/;
const checkScrollable = (node, prop) => !REGEXP_SCROLL_PARENT.test(window.getComputedStyle(node).overflowY || 'visible');

export function getScrollableParent(node) {
  const isElement = node instanceof HTMLElement;
  const isScrollable = isElement && (checkScrollable(node, 'overflowY') || checkScrollable(node, 'overflowX'));

  if (!node) {
    return null;
  } else if (isScrollable && (node.scrollHeight >= node.clientHeight || node.scrollWidth >= node.clientWidth)) {
    return node;
  }

  return getScrollableParent(node.parentNode) || document.body;
}

export function scrollIntoView(element, forceTop = false) {
  if (typeof window !== 'undefined') {
    window.requestAnimationFrame(() => setTimeout(() => {
      const rect = element.getBoundingClientRect();

      if (forceTop) {
        element.scrollIntoView(true);
      } else if (rect.top < 0 || rect.bottom > window.innerHeight) {
        element.scrollIntoView(!(rect.bottom > window.innerHeight));
      }
    }, 0));
  }
}

const defaultDirectionClassNames = ['negative', 'neutral', 'positive'];
export const directionClass = (direction, ...classNamesArg) => {
  const classNames = classNamesArg.length === 0 ? defaultDirectionClassNames : classNamesArg;

  switch (direction) {
    case -1: return classNames[0];
    case 1: return classNames[2];

    case 0:
    default: return classNames[1];
  }
};

function uriToBlob(uri) {
  const byteString = window.atob(uri.split(',')[1]);
  const mimeString = uri.split(',')[0].split(':')[1].split(';')[0]
  const buffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], {type: mimeString});
};

function _svgToPng(svgText) {
  // convert an svg text to png using the browser
  return new Promise(function(resolve, reject) {
    try {
      // can use the domUrl function from the browser
      const domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error('(browser doesnt support this)')
      }

      // figure out the height and width from svg text
      const heightMatch = svgText.match(/height="(\d+)/m);
      const height = heightMatch && heightMatch[1] ? parseInt(heightMatch[1],10) : 200;
      const widthMatch = svgText.match(/width="(\d+)/m);
      const width = widthMatch && widthMatch[1] ? parseInt(widthMatch[1],10) : 200;

      // it needs a namespace
      if (!svgText.match(/xmlns="/mi)){
        svgText = svgText.replace('<svg ','<svg xmlns="http://www.w3.org/2000/svg" ');
      }

      // create a canvas element to pass through
      let canvas = document.createElement('canvas');
      canvas.width = height;
      canvas.height = width;

      const ctx = canvas.getContext('2d');

      // make a blob from the svg
      const svg = new Blob([svgText], {
        type: 'image/svg+xml',
      });

      // create a dom object for that image
      const url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      const img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function() {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // we don't need the original any more
        domUrl.revokeObjectURL(url);

        // now we can resolve the promise, passing the base64 url
        window.requestAnimationFrame(() => resolve(canvas.toDataURL()));
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject('failed to convert svg to png ' + err);
    }
  });
}

export function svgToPng(svgText) {
  return _svgToPng(svgText).then(() => (
    // Dirty, dirty hack: Safari won't render all assets on the first
    // go around 90% of the time, so we need to render twice...
    new Promise((resolve) => setTimeout(() => _svgToPng(svgText).then(resolve), 300))
  ));
}

export function saveImage(name, uri) {
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(uriToBlob(uri), name);
  } else {
    const saveLink = document.createElement('a');

    if ('download' in saveLink) {
      saveLink.download = name;
      saveLink.style.display = 'none';
      document.body.appendChild(saveLink);

      try {
        const blob = uriToBlob(uri);
        const url = URL.createObjectURL(blob);
        saveLink.href = url;
        saveLink.onclick = () => requestAnimationFrame(() => URL.revokeObjectURL(url));
      } catch (e) {
        console.error(e);
        console.warn('Error while getting object URL. Falling back to string URL.');
        saveLink.href = uri;
      }

      saveLink.click();
      document.body.removeChild(saveLink);
    }
  }
}

export function windowOpenCentered(url, name, config = {}) {
  const { method, params: configParams } = config;
  delete config.method;
  delete config.params;

  const opts = {
    width: 550,
    height: 440,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...config,
  };

  opts.left = (window.outerWidth / 2) + (window.screenX || window.screenLeft || 0) - (opts.width / 2);
  opts.top = (window.outerHeight / 2) + (window.screenY || window.screenTop || 0) - (opts.height / 2);

  const windowOptions = Object.keys(opts).map(key => `${key}=${opts[key]}`).join(', ');

  if (method === 'GET' || method === undefined) {
    // perform normal GET request to open the windoow
    return window.open(url, name, windowOptions);

  } else if (method === 'POST') {
    // perform POST request to open the window
    const windowName = `'w_${Date.now() + Math.floor(Math.random() * 100000)}`;
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', windowName);

    const csrfTokenNode = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenNode ? csrfTokenNode.getAttribute('content') : undefined;
    const params = {
      authenticity_token: csrfToken,
      ...(configParams || {}),
    };

    if (params) {
      for (const i in params) {
        if (params.hasOwnProperty(i)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = i;
          input.value = params[i];
          form.appendChild(input);
        }
      }
    }

    document.body.appendChild(form);

    const w = window.open('', windowName, windowOptions);
    form.target = windowName;
    form.submit();

    document.body.removeChild(form);

    return w;
  }
}
