import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const sentry = ({ env, logger, config }) => {
  Sentry.init({
    dsn: config.dsn,
    release: `toddlerpips-frontend-js@${process.env.VERSION}`,
    environment: process.env.BP_ENV,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    sampleRate: config.sample_rate,
    tracesSampleRate: config.traces_sample_rate,
    allowUrls: config.allow_urls,
    ignoreErrors: [
      // These are fairly generic, yes, but Sentry JS reports
      // are not particularly helpful for any of these anyway
      // and consume too many paid resources. We've yet to see
      // one of these actually lead to a bug that's our fault.
      'Failed to fetch',
      'SecurityError',
      'ReferenceError',
      'UnhandledRejection',
      'TypeError',
      '<unknown>',
      'ResizeObserver',
    ],
    beforeSend(event) {
      // Ignore illegal invocation within ads related to Facebook by
      // dropping all events if query string includes `fbclid` string
      // See: https://stackoverflow.com/q/64497012
      if (
        window &&
        window.location &&
        window.location.search &&
        window.location.search.indexOf('fbclid') !== -1
      ) {
        return null;
      }

      // Otherwise just let it though
      return event;
    }
  });
};

export default sentry;
