import { EventEmitter } from 'events';
import debounce from 'p-debounce';

import { promiseScript, ensureArray, stringify } from './utils';

import logger from './logger';

function loadGPTScript() {
  return new Promise((resolve, reject) => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    promiseScript('dfp', `${document.location.protocol}//securepubads.g.doubleclick.net/tag/js/gpt.js`)
      .then(() => resolve(window.googletag))
      .catch(reject);
  });
}

const registeredSlots = {};

let loadPromise = null;
let managerAlreadyInitialized = false;
let googleGPTScriptLoadPromise = null;
let refreshableSlots = new Set([]);
let refreshableTimeout = null;

class DFPManager extends EventEmitter {
  constructor() {
    super();
    this.setMaxListeners(0);
    this.options = {
      singleRequest: true,
      disableInitialLoad: false,
      collapseEmptyDivs: true,
    };
  }

  configure(opts) {
    this.options = {
      ...this.options,
      ...opts,
    };
  }

  get googleTag() {
    if (googleGPTScriptLoadPromise === null) {
      googleGPTScriptLoadPromise = loadGPTScript().catch((err) => {
        logger.error('[dfp] Failed to load DFP.', err);
        this.emit('dfpFailedLoad');
      });
    }

    return googleGPTScriptLoadPromise;
  }

  get registeredSlots() {
    return registeredSlots;
  }

  init() {
    if (managerAlreadyInitialized === false) {
      managerAlreadyInitialized = true;

      this.googleTag.then((googleTag) => {
        googleTag.cmd.push(() => {
          const pubadsService = googleTag.pubads();

          pubadsService.addEventListener('slotRenderEnded', (event) => {
            const slotId = event.slot.getSlotElementId();
            this.emit('slotRenderEnded', { slotId, event });
          });

          pubadsService.addEventListener('impressionViewable', (event) => {
            const slotId = event.slot.getSlotElementId();
            this.emit('impressionViewable', { slotId, event });
          });

          pubadsService.addEventListener('slotVisibilityChanged', (event) => {
            const slotId = event.slot.getSlotElementId();
            this.emit('slotVisibilityChanged', { slotId, event });
          });
        });
      }).catch((err) => {
        logger.error('[dfp] Failed to load DFP.', err);
        this.emit('dfpFailedLoad');
      });
    }
  }

  configureInitialOptions(googleTag) {
    googleTag.cmd.push(() => {
      if (this.options.disableInitialLoad) {
        googleTag.pubads().disableInitialLoad();
      }
    });
  }

  configureOptions(googleTag) {
    googleTag.cmd.push(() => {
      const pubadsService = googleTag.pubads();

      const targetingArgs = Object.keys(window.bpAdTargets || {});
      targetingArgs.forEach((key) => {
        const value = window.bpAdTargets[key];
        const targetingValues = ensureArray(value).map(stringify);
        pubadsService.setTargeting(key, targetingValues);
      });

      pubadsService.enableSingleRequest();
      pubadsService.collapseEmptyDivs();
    });
  }

  scheduleLoad = debounce(() => {
    const slotKeys = Object.keys(this.registeredSlots);
    if (slotKeys.length > 0) {
      logger.info(`[dfp] Loading ${slotKeys.length} slots`);
      this.load().catch((err) => {
        logger.error('[dfp] Failed to load ads.');
      });
    }
  }, 100)

  load(...slots) {
    if (loadPromise === null) {
      loadPromise = this.doLoad(...slots);
    } else {
      loadPromise = loadPromise.then(() => this.doLoad(...slots));
    }

    return loadPromise;
  }

  doLoad(...slots) {
    this.init();
    let availableSlots = {};

    if (slots.length > 0) {
      availableSlots = slots.filter((slotId) => (
        Object.prototype.hasOwnProperty.call(registeredSlots, slotId)
      ));
    } else {
      availableSlots = Object.keys(registeredSlots);
    }

    availableSlots = availableSlots.filter((id) => (
      !registeredSlots[id].loading && !registeredSlots[id].gptSlot
    ));

    availableSlots.forEach((slotId) => {
      registeredSlots[slotId].loading = true;
    });

    return this.gptLoadAds(availableSlots);
  }

  gptLoadAds(slotsToInitialize) {
    return new Promise((resolve, reject) => {
      this.googleTag.then((googleTag) => {
        this.configureInitialOptions(googleTag);

        slotsToInitialize.forEach((currentSlotId) => {
          registeredSlots[currentSlotId].loading = false;

          googleTag.cmd.push(() => {
            const slot = registeredSlots[currentSlotId];
            const gptSlot = googleTag.defineSlot(slot.unitId, slot.sizes, currentSlotId);

            if (gptSlot !== null) {
              slot.gptSlot = gptSlot;

              if (slot.targets && Object.keys(slot.targets).length > 0) {
                Object.keys(slot.targets).forEach((key) => {
                  const value = slot.targets[key];
                  const targetingValues = ensureArray(value).map(stringify);
                  slot.gptSlot.setTargeting(key, targetingValues);
                });
              }

              slot.gptSlot.addService(googleTag.pubads());

              if (slot.mapping && slot.mapping.length > 0) {
                let smbuilder = googleTag.sizeMapping();
                let addedSizes = 0;
                slot.mapping.forEach((sizes) => {
                  if (sizes.length > 1 && sizes[1].length > 0) {
                    if (sizes[1].length === 1 && sizes[1][0].length === 0) {
                      sizes[1] = []; // ignore empty sizes
                    }

                    smbuilder = smbuilder.addSize(sizes[0], sizes[1]);
                    addedSizes += 1;
                  }
                });

                if (addedSizes > 0) {
                  const sizeMapping = smbuilder.build();
                  if (sizeMapping) {
                    slot.gptSlot.defineSizeMapping(sizeMapping);
                  }
                }
              }
            }
          });
        });

        this.configureOptions(googleTag);

        googleTag.cmd.push(() => {
          googleTag.enableServices();

          if (!this.options.disableInitialLoad) {
            slotsToInitialize.forEach((slotId) => {
              googleTag.display(slotId);
            });
          }

          resolve();
        });
      }).catch(reject);
    });
  }

  refreshSlot(slotId) {
    logger.debug(`[dfp] Requested to refresh slot '${slotId}'`);

    refreshableSlots.add(slotId);

    if (!refreshableTimeout) {
      refreshableTimeout = setTimeout(() => {
        this.refresh(...Array.from(refreshableSlots));
        refreshableSlots.clear();
        refreshableTimeout = null;
      }, 300);
    }
  }

  refresh(...slots) {
    if (loadPromise === null) {
      this.load();
    } else {
      this.gptRefreshAds(slots);
    }
  }

  gptRefreshAds(slots) {
    return this.googleTag.then((googleTag) => {
      this.configureOptions(googleTag);
      googleTag.cmd.push(() => {
        const pubadsService = googleTag.pubads();
        const refreshable = slots.map(slotId => registeredSlots[slotId].gptSlot);

        if (refreshable.length > 0) {
          pubadsService.refresh(refreshable);
        }
      });
    });
  }

  registerSlot({
    id,
    slotId,
    unitId,
    sizes,
    mapping,
    targets,
  }, autoLoad = true) {
    if (!Object.prototype.hasOwnProperty.call(registeredSlots, slotId)) {
      registeredSlots[slotId] = {
        id,
        unitId,
        slotId,
        sizes,
        targets,
        mapping,
        loading: false,
        gptSlot: undefined,
      };

      logger.debug(`[dfp] Registered ad slot '${slotId}' for unit '${unitId}' (${id})`, targets);
      this.emit('slotRegistered', { slotId, unitId });

      if (autoLoad === true && loadPromise !== null) {
        logger.debug(`[dfp] Loading ad slot '${slotId}' immediately`);

        loadPromise = loadPromise.catch().then(() => {
          const slot = registeredSlots[slotId];

          if (typeof slot !== 'undefined') {
            const { loading, gptSlot } = slot;

            if (loading === false && !gptSlot) {
              this.load(slotId);
            }
          }
        });
      }
    }
  }

  unregisterSlot({ slotId }) {
    this.destroyGPTSlots(slotId);
    delete registeredSlots[slotId];
  }

  destroyGPTSlots(...slotsToDestroy) {
    if (slotsToDestroy.length === 0) {
      slotsToDestroy = Object.keys(registeredSlots);
    }

    return new Promise((resolve) => {
      const slots = slotsToDestroy.map((slotId) => registeredSlots[slotId]);

      this.googleTag.then((googleTag) => {
        googleTag.cmd.push(() => {
          if (managerAlreadyInitialized === true) {
            if (slotsToDestroy.length > 0) {
              slots.forEach((slot) => {
                logger.debug(`[dfp] Unregistered ad slot '${slot.slotId}' for unit '${slot.unitId}' (${slot.id})`);
                slots.push(slot.gptSlot);
                slot.gptSlot = undefined;
              });

              googleTag.destroySlots(slots);
            } else {
              logger.debug('[dfp] Unregistered all existing ad slots.');
              googleTag.destroySlots();
            }
          }

          resolve(slotsToDestroy);
        });
      });
    });
  }
}

const manager = new DFPManager();
export default manager;
