import React from 'react';
import PropTypes from 'prop-types';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';

import { findAdUnit } from '../../lib/adUnit';

const publisher = 'babypips-com';

const FreestarAd = ({
  className,
  containerRef,
  id,
  slotId,
  targets,
}) => {
  const adUnit = findAdUnit(id);
  const { placement } = adUnit.unit.freestar || {};

  return (
    <FreestarAdSlot
      publisher={publisher}
      placementName={placement}
      slotId={slotId}
      targeting={targets}
    />
  );
};

FreestarAd.propTypes = {
  id: PropTypes.string.isRequired,
  targets: PropTypes.object,
};

FreestarAd.defaultProps = {
  targets: undefined,
};

export default FreestarAd;
