export default function loadStyles(styles) {
  (styles || []).forEach((asset) => {
    const doc = window.document;
    const el = doc.createElement('link');
    const refs = doc.getElementsByTagName('link');
    const ref = refs[refs.length-1];
    const onload = () => {
      el.onload = null;
      el.media = 'all';
    };

    el.rel = 'stylesheet';
    el.href = asset;
    el.media = 'only x';
    el.onload = onload;

    ref.parentNode.insertBefore(el, ref.nextSibling);
  });
}
