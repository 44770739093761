import { getAuthenticityToken, inSameDomain, objectToGetParams } from './utils';

const fetchHtml = (path, opts = {}) => {
  const { data, headers } = opts;
  delete opts.data;
  delete opts.headers;

  if (!inSameDomain(path)) {
    throw new Error(`path (${path}) should be within the same domain`);
  }

  opts.headers = {
    'Accept': 'text/html',
    'Content-Type': 'text/html',
    'X-CSRF-Token': getAuthenticityToken(),
    'X-Requested-With': 'XmlHttpRequest',
    ...headers,
  };

  return fetch(`${path}${data ? objectToGetParams(data) : ''}`, opts)
    .then((res) => {
      if (res.status >= 400) {
        throw new Error(`request error, status was ${res.status}`);
      }

      return res.text();
    })
    .then((text) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');

      return doc;
    });
};

export default fetchHtml;
