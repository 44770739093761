/* eslint-disable no-console */
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

const CLASS_NAME_ATTR = 'data-react-class';
const PROPS_ATTR = 'data-react-props';
const RENDER_ATTR = 'data-hydrate';

function discoverUnmountedComponents() {
  const selector = `[${CLASS_NAME_ATTR}]`;
  const nodes = document.querySelectorAll(selector);
  const registeredComponents = window.__mountedComponents;

  for (const node of nodes) {
    const className = node.getAttribute(CLASS_NAME_ATTR);

    if (!registeredComponents.has(className)) {
      const message = `React component '${className}' was not mounted within 300ms`;
      console.warn(`%c[react-rails] %c${message} for element:`, 'font-weight: bold', 'font-weight: normal', node);
    }
  }

  delete window.__unmountedCheck;
}

export function mountComponents(context) {
  const keys = Object.keys(context);

  const mount = (parent) => {
    for (const key of keys) {
      const selector = `[${CLASS_NAME_ATTR}="${key}"]`;
      const nodes = parent.querySelectorAll(selector);

      for (let i = 0; i < nodes.length; ++i) {
        const node = nodes[i];
        const component = context[key];
        const constructor = component.__esModule ? context[key].default : context[key];
        const propsJson = node.getAttribute(PROPS_ATTR);
        const props = propsJson && JSON.parse(propsJson);
        const doHydrate = node.getAttribute(RENDER_ATTR);

        if (!constructor) {
          const message = `Cannot find component in current context: '${key}'`;

          if (console && console.log) {
            console.log(`%c[react-rails] %c${message} for element,`, 'font-weight: bold', 'font-weight: normal', node);
            console.log('%c[react-rails] %cCurrent context:', 'font-weight: bold', 'font-weight: normal', context);
          }

          throw new Error(`${message}. Make sure your component is available to render.`);
        }

        const reactComponent = React.createElement(constructor, props);

        if (doHydrate && typeof hydrate === 'function') {
          hydrateRoot(node, reactComponent);
        } else {
          const root = createRoot(node);
          root.render(reactComponent);
        }
      }
    }
  };

  if (process.env.NODE_ENV === 'development') {
    if (window.__unmountedCheck !== null) {
      clearTimeout(window.__unmountedCheck);
    }

    if (typeof window.__mountedComponents === 'undefined') {
      window.__mountedComponents = new Set([]);
    }

    window.__unmountedCheck = setTimeout(discoverUnmountedComponents, 300);
    window.__mountedComponents = new Set([...Array.from(window.__mountedComponents), ...keys]);
  }

  window.addEventListener('turbo-frame:loaded', (e) => {
    const { newFrame } = e.detail;
    mount(newFrame);
  });

  mount(document);
}
