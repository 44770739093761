import { mountComponents } from '../lib/react';
import { mountEnhancements } from '../lib/enhancements';
import loadStyles from '../lib/loadStyles';

import InstrumentationManager from '../lib/instrumentation';
import AnalyticsManager from '../lib/analytics';
import MarketingManager from '../lib/marketing';

// Initialize tracking platforms as early as possible
InstrumentationManager.initialize();
AnalyticsManager.initialize();
MarketingManager.initialize();

// Copy all static images under ../images to the output folder
require.context('../images', true);

// Copy all static inline-able SVG icons under ../icons/inline to the output folder
require.context('../icons/inline', true);

mountComponents({
  'Ads/AdUnit': require('../components/Ads/AdUnit'),
  'CategoryNavigation': require('../components/CategoryNavigation'),
  'SiteSearch': require('../components/SiteSearch'),
});

mountEnhancements({
  'lazysizes': require('../enhancements/lazysizes'),
  'relativeTimestamps': require('../enhancements/relativeTimestamps'),
  'stickybar': require('../enhancements/stickybar'),
  'turboFrame': require('../enhancements/turboFrame'),
  'modals': require('../enhancements/modals'),
});

(() => {
  loadStyles(window.bpLazyStylesheets);
})();
