import ThirdPartyManager from './thirdPartyManager';
import Sentry from './analytics/sentry';

class Manager extends ThirdPartyManager {
  constructor() {
    super('[instrumentation]');
  }

  initialize() {
    this.initializeBackends({
      Sentry,
    });
  }
}

const manager = new Manager();
export default manager;
