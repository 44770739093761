import Sticky from 'react-stickynode';
import PropTypes from 'prop-types';

export default class StackingSticky extends Sticky {

  static propTypes = {
    enabled: PropTypes.bool,
    top: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    bottomBoundary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.func
    ]),
    stackBoundary: PropTypes.func,
    enableTransforms: PropTypes.bool,
    activeClass: PropTypes.string,
    releasedClass: PropTypes.string,
    onStateChange: PropTypes.func,
    shouldFreeze: PropTypes.func,
    innerZ: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  };

  // Overridden method from Sticky component
  getBottomBoundary(bottomBoundary) {
    // a bottomBoundary can be provided to avoid reading from the props
    let boundary = bottomBoundary || this.props.bottomBoundary;

    if (typeof boundary === 'string') {
      if (!this.bottomBoundaryTarget) {
        this.bottomBoundaryTarget = document.querySelector(boundary);
      }
      boundary = this.getTargetBottom(this.bottomBoundaryTarget);
    }

    // CUSTOM: Allow for calculated boundaries
    if (typeof boundary === 'function') {
      const boundaryReturn = boundary(this.scrollTop);
      boundary = (typeof boundaryReturn === 'number') ? boundaryReturn : boundary;
    }

    // CUSTOM: Allow for stacking by overriding the bottom to be the stacking element's top
    if (typeof this.props.stackBoundary === 'function') {
      const stackBoundary = this.props.stackBoundary(this.scrollTop);
      boundary = (typeof stackBoundary === 'number') ? stackBoundary : boundary;
    }

    return boundary && boundary > 0 ? boundary : Infinity;
  }

}