import uniqWith from 'lodash/uniqWith';
import flatMap from 'lodash/flatMap';

import adUnits from '../../../config/settings/ad_units.yml';

let adUnitId = 0;

function nextAdUnitId() {
  return adUnitId++;
}

function sizeClassMappingMin(sizeClass) {
  return adUnits.size_class_min[sizeClass] || [0, 0];
}

function sizesFromMapping(mapping) {
  return uniqWith(flatMap(mapping || {}, (sizes) => (
    sizes
  )), (a,b) => (
    a[0] === b[0] && a[1] === b[1]
  ));
}

function makeAdUnit(id, unit) {
  const slotId = `ad-unit-${nextAdUnitId()}`;
  const sizes = (typeof unit.sizes !== 'undefined') ? unit.sizes : sizesFromMapping(unit.mapping);
  const mapping = Object.entries(unit.mapping || {}).map(([sizeClass, sizes]) => (
    [sizeClassMappingMin(sizeClass), sizes]
  ));

  return {
    id,
    unit,
    unitId: unit.id,
    sizes,
    mapping,
    slotId,
  };
}

export const adsEnabled = adUnits.enabled;
export const adPlaceholders = (network) => adUnits.placeholders[network] !== false;

export const findAdUnit = (id) => (
  typeof adUnits[id] !== 'undefined' ? makeAdUnit(id, adUnits[id]) : null
);
