import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import fetchHtml from '../lib/fetchHtml';

import Modal from './Modal';
import Loader from './Loader';

const TurboModal = ({
  className,
  path,
  onClose,
}) => {
  const modalRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [content, setContent] = useState(null);

  const loadUrl = useCallback((e) => {
    e?.stopPropagation();

    setLoaded(false);
    setError(null);
    fetchHtml(path)
      .then((doc) => {
        setError(null);
        setLoaded(true);
        setContent(doc.firstChild.innerHTML);
      })
      .catch((err) => {
        setError(err);
        setLoaded(true);
        console.error(err);
      });
  }, [path]);

  useEffect(() => {
    const onOutsideClick = (e) => {
      if (!modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    const onKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.closeModal = onClose;

    setTimeout(() => {
      document.addEventListener('click', onOutsideClick);
      document.addEventListener('keydown', onKeyDown);
    }, 0);

    return () => {
      window.closeModal = undefined;
      document.removeEventListener('click', onOutsideClick);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  useEffect(() => loadUrl(), [loadUrl]);

 return (
    <Modal ref={modalRef} className={className}>
      <Modal.Header onRequestClose={onClose} />
      <Modal.Content>
        {!loaded ? (
          <Loader loaded={loaded} />
        ) : null}
        {error ? (
          <Modal.Error onTryAgain={loadUrl} />
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: content }}/>
      </Modal.Content>
    </Modal>
  );
};

TurboModal.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

TurboModal.defaultProps = {
  className: undefined,
  onClose: () => {},
};

export default TurboModal;

