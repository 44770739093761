import isEmpty from 'lodash/isEmpty';

import moment from '../lib/moment';

function findTimestamps() {
  return document.querySelectorAll('time[datetime]');
}

const relativeTimestamps = () => {
  const tz = moment.tz.guess();
  const nodes = findTimestamps();

  for (const node of nodes) {
    const timestamp = node.getAttribute('datetime');
    const isRelative = node.getAttribute('data-relative') === 'true';
    const format = node.getAttribute('data-format');

    if (typeof timestamp === 'string' && !isEmpty(timestamp)) {
      if (isRelative) {
        node.textContent = moment(timestamp).fromNow();
      } else if (typeof format === 'string' && !isEmpty(format)) {
        node.textContent = moment(timestamp).tz(tz).format(format);
      }
    }
  }
};

export default relativeTimestamps;