import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

import TextAd from './TextAd';

import { DONT_TRY, getAvailableDimensions, isEligible } from './utils';

import DFPManager from '../../lib/dfpManager';

const DfpAd = ({
  className,
  containerRef,
  id,
  slotId,
  unitId,
  sizes,
  mapping,
  targets,
}) => {
  const renderedDimensions = useRef([]);
  const [textProps, setTextProps] = useState(undefined);

  useEffect(() => {
    const slotProps = {
      id,
      slotId,
      unitId,
      sizes,
      mapping,
      targets,
    };

    const onFailedLoad = () => {
      containerRef.applySizeClasses({ size: [], isEmpty: true });
      renderedDimensions.current = DONT_TRY;
    };

    const onSlotRenderEnded = ({ slotId, event }) => {
      if (slotId === slotProps.slotId && containerRef.current) {
        const { size, isEmpty } = event;
        containerRef.applySizeClasses({ size, isEmpty });
      }

      renderedDimensions.current = getAvailableDimensions(mapping);
    };

    const onReceiveMessage = (e) => {
      const key = e.message ? 'message' : 'data';
      const data = e[key] || {};
      const eventName = data.message || 'ignore';

      if (eventName === 'adContentAvailable' && data.adUnit === unitId) {
        switch (data.type) {
          case 'text':
            setTextProps(data.props);
            break;

          default:
            console.warn(`Received unknown ad type '${data.type}'`);
            break;
        }
      }
    };

    DFPManager.registerSlot(slotProps);
    DFPManager.on('dfpFailedLoad', onFailedLoad);
    DFPManager.on('slotRenderEnded', onSlotRenderEnded);
    window.addEventListener('message', onReceiveMessage);

    return () => {
      DFPManager.unregisterSlot(slotProps);
      DFPManager.off('dfpFailedLoad', onFailedLoad);
      DFPManager.off('slotRenderEnded', onSlotRenderEnded);
      window.removeEventListener('message', onReceiveMessage);
    };
  }, [setTextProps, id, slotId, unitId, mapping, sizes, targets, containerRef]);

  useEffect(() => {
    const onRefresh = throttle(() => {
      if (isEligible(mapping, sizes)) {
        DFPManager.refreshSlot(slotId);
      }
    }, 1000);

    const onResize = throttle(() => {
      if (renderedDimensions.current === DONT_TRY) {
        return;
      }

      const availableDimensions = getAvailableDimensions(mapping);
      if (isEligible(mapping, sizes) && !isEqual(availableDimensions, renderedDimensions.current)) {
        DFPManager.refreshSlot(slotId);
      }
    }, 500);

    window.addEventListener('resize', onResize);
    window.addEventListener('ads:refresh', onRefresh);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('ads:refresh', onRefresh);
    };
  }, [slotId, mapping, sizes, renderedDimensions]);

  useEffect(() => {
    DFPManager.scheduleLoad();
  });

  return (
    <div
      id={slotId}
      className={classnames(className)}
    >
      {textProps ? (
        <TextAd
          link={textProps.link}
          title={textProps.title}
          target={textProps.target}
          text={textProps.text}
          impression={textProps.impression}
        />
      ) : null}
    </div>
  );
};

DfpAd.propTypes = {
  className: PropTypes.string,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  id: PropTypes.string.isRequired,
  slotId: PropTypes.string.isRequired,
  unitId: PropTypes.string.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  mapping: PropTypes.array.isRequired,
  targets: PropTypes.object,
};

DfpAd.defaultProps = {
  className: undefined,
  targets: undefined,
};

export default DfpAd;
