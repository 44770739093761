import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { trackEvent } from '../lib/analytics';
import { sanitizeQuery } from '../lib/utils';

const SiteSearch = ({
  searchUrl,
}) => {
  const wrapperRef = useRef(null);
  const fieldRef = useRef(null);
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onClearClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setQuery('', () => {
      if (fieldRef.current) {
        fieldRef.current.focus();
      }
    });
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(false);
    setQuery('');
  };

  const onSearchClick = (e) => {
    setOpen(true);
  };

  const onSubmit = (e) => {
    trackEvent({
      category: 'Search',
      action: 'Query',
      payload: {
        placement: 'header',
        q: sanitizeQuery(query),
      },
    });
  };

  useEffect(() => {
    if (wrapperRef.current) {
      const parent = wrapperRef.current.parentNode;
      parent.classList.toggle('site-search-open', isOpen);
    }

    if (fieldRef.current && isOpen) {
      fieldRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div ref={wrapperRef}>
      {isOpen ? (
        <form method="get" action={searchUrl} onSubmit={onSubmit} className="search-form">
          <div className="search-field">
            <input
              ref={fieldRef}
              type="text"
              name="q"
              required="required"
              value={query}
              onChange={onQueryChange}
            />
            {!isEmpty(query) ? (
              <button
                className="clear-button"
                tabIndex="-1"
                type="button"
                onClick={onClearClick}
              >
                Clear
              </button>
            ) : null}
          </div>
          <button
            className="close-button"
            tabIndex="-1"
            type="button"
            onClick={onCloseClick}
          >
            Close
          </button>
        </form>
      ) : (
        <button
          className="search-button"
          type="button"
          onClick={onSearchClick}
        >
          Search
        </button>
      )}
    </div>
  );
};

SiteSearch.propTypes = {
  searchUrl: PropTypes.string.isRequired
};

export default SiteSearch;