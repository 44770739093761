import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './TextAd.module.scss';

const TextAd = ({
  className,
  link,
  title,
  target,
  text,
  impression,
}) => (
  <div className={classnames(styles.container, className)}>
    <a href={link} title={title} target={target}>
      {text}
    </a>
    {impression ? (
      <img
        src={impression}
        width={1}
        height={1}
        border={0}
        alt=""
      />
    ) : null}
  </div>
);

TextAd.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
  target: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  impression: PropTypes.string,
};

TextAd.defaultProps = {
  className: undefined,
  title: undefined,
  impression: undefined,
};

export default TextAd;
