import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

const sortSizes = (sizes) => (
  Array.from(sizes).sort((a, b) => (
    a[1] <= b[1] ? -1 : 1
  ))
);

export const DONT_TRY = 'dt';

export const getAvailableDimensions = (mapping) => (
  (typeof window !== 'undefined') ? mapping.filter((dimension) => dimension[0][0] <= window.innerWidth) : []
);

export const isEligible = (mapping, sizes) => {
  const availableDimensions = getAvailableDimensions(mapping);
  const currentMapping = availableDimensions.length > 0 ? availableDimensions[0][1] : undefined;
  return (
    (mapping.length === 0 && sizes.length > 0)
    || (currentMapping && currentMapping.length > 0 && currentMapping[0].length > 0)
  );
};

export const useContainerRef = (initialValue = null) => {
  const innerRef = useRef(initialValue);
  const ref = (value) => {
    if (value && value.parentNode) {
      innerRef.current = value.parentNode;
    }
  };

  if (!ref.current) {
    Object.defineProperty(ref, 'current', {
      get() {
        return innerRef.current;
      },
    });
  }

  if (!ref.applySizeClasses) {
    ref.applySizeClasses = ({ size, isEmpty }) => {
      const root = innerRef.current;

      if (root) {
        if (size && size.length > 0) {
          root.dataset.width = size[0] || 0;
          root.dataset.height = size[1] || 0;
        }

        root.classList.toggle('no-ad', isEmpty);
      }
    };
  }

  return ref;
};

export const useDisplayable = (containerRef, mapping, sizes, id) => {
  const [displayable, setDisplayable] = useState(mapping.length === 0 && sizes.length > 0);

  useEffect(() => {
    const onResize = throttle(() => {
      const container = containerRef.current ? containerRef.current.childNodes[0] : undefined;

      for (const currentMapping of mapping) {
        const [sizeClass, sizes] = currentMapping;
        const mq = window.matchMedia(`(min-width: ${sizeClass[0]}px)`);

        if (mq.matches) {
          setDisplayable(sizes.length > 0);

          const minimum = sortSizes(sizes)[0];
          if (minimum && container) {
            container.style.minWidth = `${minimum[0]}px`;
            container.style.minHeight = `${minimum[1]}px`;
          }

          // Finish trying to match any ad sizes
          return;
        }
      }

      // If we did not match any ad sizes, this ad is not displayable
      setDisplayable(false);
    }, 300);

    if (mapping.length > 0) {
      onResize();
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, [containerRef, mapping, sizes, id]);

  return displayable;
};
