import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DfpAd from './DfpAd';
import FreestarAd from './FreestarAd';
import PlaceholderAd from './PlaceholderAd';

import StackingSticky from '../StackingSticky';

import { useContainerRef, useDisplayable } from './utils';

import { adsEnabled, adPlaceholders, findAdUnit } from '../../lib/adUnit';
import { isServerSide, getNextSibling } from '../../lib/utils';
import logger from '../../lib/logger';

import styles from './AdUnit.module.scss';

const STACKING_OFFSET = 300;
const STICKY_BOUNDARY_OFFSET = 300;

const networkComponents = {
  dfp: DfpAd,
  freestar: FreestarAd,
};

const AdUnit = ({
  className,
  slot_id,
  unit_id,
  network,
  targets,
  sticky,
}) => {
  const adUnit = findAdUnit(unit_id);
  const { id, slotId, unitId, sizes, mapping } = adUnit || {};
  const AdComponent = adPlaceholders(network) ? PlaceholderAd : networkComponents[network];

  const containerRef = useContainerRef(null);
  const displayable = useDisplayable(containerRef, mapping, sizes, id);

  const stickyBottomBoundary = useCallback((scrollTop) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const rect = container.parentNode.getBoundingClientRect();
      return scrollTop + rect.bottom - STICKY_BOUNDARY_OFFSET;
    } else {
      return null;
    }
  }, [containerRef]);

  const stickyStackBoundary = useCallback((scrollTop) => {
    if (containerRef.current) {
      const target = getNextSibling(containerRef.current, '.ad-unit.sticky');

      if (target) {
        const rect = target.getBoundingClientRect();
        return scrollTop + rect.top - STACKING_OFFSET;
      } else {
        return null;
      }
    }
  }, [containerRef]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (!containerRef.current) {
        return;
      }

      const style = window.getComputedStyle(containerRef.current);
      if (style?.display === 'none') {
        containerRef.applySizeClasses({ size: [], isEmpty: true });
      }
    });
  }, [containerRef]);

  if (isServerSide() || !adsEnabled) {
    return null;
  } else if (!adUnit) {
    logger.warn(`Ad unit '${unit_id}' was not found in ad definitions.`);
    return null;
  }

  return displayable ? (
    <div
      ref={containerRef}
      className={classnames(styles.container, className)}
    >
      {sticky ? (
        <StackingSticky top={40} bottomBoundary={stickyBottomBoundary} stackBoundary={stickyStackBoundary}>
          <AdComponent
            containerRef={containerRef}
            network={network}
            id={id}
            slotId={slot_id || slotId}
            unitId={unitId}
            sizes={sizes}
            mapping={mapping}
            targets={targets}
          />
        </StackingSticky>
      ) : (
        <AdComponent
          containerRef={containerRef}
          network={network}
          id={id}
          slotId={slot_id || slotId}
          unitId={unitId}
          sizes={sizes}
          mapping={mapping}
          targets={targets}
        />
      )}
    </div>
  ) : null;
};

AdUnit.propTypes = {
  className: PropTypes.string,
  unit_id: PropTypes.string.isRequired,
  network: PropTypes.string,
  targets: PropTypes.object,
  sticky: PropTypes.bool,
};

AdUnit.defaultProps = {
  className: undefined,
  network: 'dfp',
  targets: undefined,
  sticky: false,
};

export default AdUnit;
