import React from 'react';
import { createRoot } from 'react-dom/client';

import TurboModal from '../components/TurboModal';

import { BASE_URL, inSameDomain } from '../lib/utils';
import { trackEvent } from '../lib/analytics';

function getModalContainer() {
  return document.getElementById('modal-root');
}
function getModalLinks() {
  return document.querySelectorAll('[data-turbo-modal]');
}

const modals = () => {
  const nodes = getModalLinks();
  const modalContainer = getModalContainer();

  for (const node of nodes) {
    const ds = node.dataset;
    const defaultSrc = window.location.href;
    const src = new URL(node.href || ds.turboSrc || defaultSrc, BASE_URL);
    const className = ds.turboModalClassname;
    let eventPayload = {};

    try {
      if (ds.turboEvent) {
        eventPayload = JSON.parse(ds.turboEvent);
      }
    } catch (e) {
      console.warn('Could not parse turbo button params:', e);
    }

    if (!inSameDomain(src)) {
      continue;
    }

    node.addEventListener('click', (e) => {
      e.preventDefault();

      const modalRoot = createRoot(modalContainer);
      modalRoot.render((
        <TurboModal
          className={className}
          path={src.pathname}
          onClose={() => modalRoot.unmount()}
        />
      ));

      if (eventPayload && eventPayload.category && eventPayload.action) {
        trackEvent({
          ...eventPayload,
        });
      }
    });
  }
};

export default modals;
