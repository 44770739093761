import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import LazyImage from './LazyImage';
import fetchApi from '../lib/fetchApi';

import styles from './CategoryNavigation.module.scss';

const CategoryNavigation = ({
  className,
  category,
}) => {
  const [posts, setPosts] = useState({});

  useEffect(() => {
    fetchApi(category.posts_path, { cache: 'default' })
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        setPosts({});
      });
  }, [category.posts_path]);

  return (
    <ul className={styles.container}>
      {category.children.map((child) => (
        <li key={child.slug} className={classnames(category.slug, child.slug)}>
          <a href={child.path}>{child.name}</a>
          <ul className={classnames(styles.navigation, 'category-navigation')}>
            {(posts[child.slug] || []).map((post) => (
              <li key={post.id} className={styles.postSummary}>
                <figure>
                  {!isEmpty(post.featured_media_src) ? (
                    <div className={styles.postThumbnail}>
                      <a href={post.path}>
                        <LazyImage
                          className={post.featured_media_white ? 'darken' : undefined}
                          src={post.featured_media_src}
                          alt={post.title}
                          title={post.title}
                        />
                      </a>
                    </div>
                  ) : null}
                </figure>
                <a href={post.path} className={styles.postHeadline}>
                  {post.title}
                </a>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

CategoryNavigation.propTypes = {
  category: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    posts_path: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string,
    })),
  }).isRequired,
};

CategoryNavigation.defaultProps = {
  className: undefined,
};

export default CategoryNavigation;