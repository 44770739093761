import ThirdPartyManager from './thirdPartyManager';

import FacebookPixel from './marketing/facebookPixel';

class Manager extends ThirdPartyManager {

  constructor() {
    super('[marketing]');
  }

  initialize() {
    this.initializeBackends({
      fbpixel: FacebookPixel,
    });
  }

}

const manager = new Manager();
export default manager;
