import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

import TextAd from './TextAd';

import { getAvailableDimensions } from './utils';

import styles from './PlaceholderAd.module.scss';

const PlaceholderAd = ({
  className,
  containerRef,
  id,
  slotId,
  unitId,
  sizes,
  mapping,
  network,
  targets,
}) => {
  const [currentDimensions, setCurrentDimensions] = useState([0, 0]);
  const [showDebug, setShowDebug] = useState(false);
  const pageTargets = window.bpAdTargets;

  const onPlaceholderClick = useCallback(() => {
    setShowDebug(!showDebug);
  }, [showDebug]);

  const refreshAd = useCallback(() => {
    if (mapping.length === 0 && sizes.length > 0) {
      setCurrentDimensions(sizes[0]);
    } else {
      const availableDimensions = getAvailableDimensions(mapping);
      const newMapping = availableDimensions.length > 0 ? availableDimensions[0][1] : [];
      setCurrentDimensions(newMapping.length > 0 ? newMapping[0] : []);
    }
  }, [sizes, mapping]);

  const onWindowResize = useCallback(throttle(() => { // eslint-disable-line react-hooks/exhaustive-deps
    const availableDimensions = getAvailableDimensions(mapping);
    if (!isEqual(availableDimensions, currentDimensions)) {
      refreshAd();
    }
  }, 500), [currentDimensions, mapping, refreshAd]);

  useEffect(() => {
    refreshAd();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    containerRef.applySizeClasses({ size: currentDimensions, isEmpty: false });
  }, [containerRef, currentDimensions[0], currentDimensions[1]]); // eslint-disable-line react-hooks/exhaustive-deps

  if (id === 'text_ad') {
    return currentDimensions.length > 0 ? (
      <TextAd
        className={className}
        id={id}
        slotId={slotId}
        link="https://www.babypips.com"
        title="Babypips.com rulz"
        target="_blank"
        text="Isn't it time you expected more from your broker? Switch to FOREX.com today."
      />
    ) : null;
  }

  return (
    <div
      id={slotId}
      onClick={onPlaceholderClick}
      className={classnames(
        styles.container,
        showDebug ? styles.debug : undefined,
        className,
      )}
      style={{
        width: `${currentDimensions[0]}px`,
        height: `${currentDimensions[1]}px`,
        lineHeight: `${currentDimensions[1]}px`
      }}
    >
      {showDebug ? (
        <dl className={styles.debugList}>
          <dt>Network:</dt>
          <dd>{network}</dd>
          <dt>Unit ID:</dt>
          <dd>{unitId}</dd>
          <dt>Slot ID:</dt>
          <dd>{slotId}</dd>
          <dt>Size:</dt>
          <dd>{currentDimensions[0]}x{currentDimensions[1]}</dd>
          <dt>Unit Targets:</dt>
          {targets ? Object.keys(targets).map((key) => (
            <dd key={key}>{key} = {targets[key]}</dd>
          )) : (
            <dd><em>none</em></dd>
          )}
          <dt>Page Targets:</dt>
          {pageTargets ? Object.keys(pageTargets).map((key) => (
            <dd key={key}>{key} = {pageTargets[key]}</dd>
          )) : (
            <dd><em>none</em></dd>
          )}
        </dl>
      ) : (
        <>
          {currentDimensions[0]}x{currentDimensions[1]}
        </>
      )}
    </div>
  );
};

PlaceholderAd.propTypes = {
  className: PropTypes.string,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  id: PropTypes.string.isRequired,
  slotId: PropTypes.string.isRequired,
  unitId: PropTypes.string.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  mapping: PropTypes.array.isRequired,
  network: PropTypes.string,
  targets: PropTypes.object,
};

PlaceholderAd.defaultProps = {
  className: undefined,
  targets: undefined,
  network: '',
};

export default PlaceholderAd;
