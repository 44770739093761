import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Logo } from '../icons/logo.svg';

import * as routes from '../lib/routes';
import * as publisher from '../lib/publisher';

import styles from './Modal.module.scss';

const Modal = React.forwardRef(({
  className,
  children,
}, ref) => {
  const mount = document.getElementById('modal-root');
  const el = document.createElement('div');

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal((
    <div ref={ref} className={classnames(styles.container, className)}>
      {children}
    </div>
  ), el);
});

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Modal.defaultProps = {
  className: undefined,
  children: null,
};

Modal.Header = ({
  className,
  onRequestClose,
  children,
}) => (
  <header className={classnames(styles.header, className)}>
    {children}
    <button className={styles.closeButton} onClick={onRequestClose} />
  </header>
);

Modal.Header.propTypes = {
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
};

Modal.Header.defaultProps = {
  className: undefined,
  onRequestClose: () => {},
  children: (
    <a href={routes.root} className={styles.siteTitle}>
      <Logo className={styles.logo} />
      {publisher.name}
    </a>
  ),
};

Modal.Content = ({
  className,
  children,
}) => (
  <section className={classnames(styles.content, className)}>
    {children}
  </section>
);

Modal.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Modal.Content.defaultProps = {
  className: undefined,
  children: null,
};

Modal.Error = ({
  className,
  children,
  onTryAgain,
}) => (
  <section className={classnames(styles.error, className)}>
    <p>{children ? children : <>Content Failed to Load</>}</p>
    <button onClick={onTryAgain}>Try Again</button>
  </section>
);

Modal.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onTryAgain: PropTypes.func,
};

Modal.Content.defaultProps = {
  className: undefined,
  children: null,
  onTryAgain: () => {},
};

export default Modal;
