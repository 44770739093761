import logger from '../lib/logger';

export function loadImages(root) {
  const tags = root.querySelectorAll('[loading="lazy"]');
  tags.forEach((el) => {
    if (!el.dataset) {
      return;
    }

    if (el.dataset.src) {
      el.src = el.dataset.src;
      delete(el.dataset.src);
    }

    if (el.dataset.srcset) {
      el.srcset = el.dataset.srcset;
      delete(el.dataset.srcset);
    }
  });
}

const lazysizes = () => {
  if ('loading' in HTMLImageElement.prototype && !('safari' in window)) {
    logger.info('native lazy loading supported');
    loadImages(document);
  } else {
    logger.warn('native lazy loading not supported, using lazysizes');
    import('lazysizes')
      .then((module) => {
        logger.debug('lazysizes loaded');
      })
      .catch((err) => {
        logger.error('failed to load lazysizes', err);
      });
  }
};

export default lazysizes;
