import { BASE_URL, getScrollableParent, objectToGetParams, scrollIntoView } from '../lib/utils';
import { loadImages } from '../enhancements/lazysizes';
import fetchHtml from '../lib/fetchHtml';

const ajaxFrame = () => {
  const restoreScrollPosition = (node, btnName, scroll) => {
    const btn = node.querySelector(`[name="${btnName}"][data-turbo-frame]`);
    if (!btn) {
      return;
    }

    const scrollable = getScrollableParent(btn);
    scrollable?.scrollTo(scroll.x, scroll.y);
  };

  const onClick = async (e) => {
    e.preventDefault();

    const btn = e.target;
    const ds = btn.dataset;
    const name = btn.name;
    const dest = ds.turboFrame;
    const scrollTop = ds.turboScrollTop;
    const defaultSrc = window.location.href;
    const src = new URL(btn.href || ds.turboSrc || defaultSrc, BASE_URL);
    const history = ds.turboHistory || (ds.turboHistory === undefined ? src !== defaultSrc : false);
    const scrollable = getScrollableParent(btn);

    let params = {};
    try {
      if (ds.turboParams) {
        params = JSON.parse(ds.turboParams);
      }
    } catch (e) {
      console.warn('Could not parse turbo button params:', e);
    }

    window.dispatchEvent(new CustomEvent('turbo-frame:load', {
      detail: {
        dest,
        src,
        params,
        history,
        btnName: name,
        scroll: scrollable ? { x: scrollable.scrollLeft, y: scrollable.scrollTop } : undefined,
        scrollTop,
      },
    }));
  };

  const bindButtons = (parent) => {
    const buttons = parent.querySelectorAll('[data-turbo-frame]');
    buttons.forEach((btn) => btn.addEventListener('click', onClick));
  };

  const unbindButtons = (parent) => {
    const buttons = parent.querySelectorAll('[data-turbo-frame]');
    buttons.forEach((btn) => btn.removeEventListener('click', onClick));
  };

  window.addEventListener('turbo-frame:load', async (e) => {
    const {
      btnName,
      dest,
      history,
      params: frameParams,
      scroll,
      scrollTop,
      src,
    } = e.detail;

    const url = typeof src === 'string' ? new URL(src, BASE_URL) : src;
    const urlParams = (typeof url.search === 'string') ? Object.fromEntries(new URLSearchParams(url.search)) : {};
    const params = { ...urlParams, ...frameParams };
    const resp = await fetchHtml(url.pathname, {
      data: params,
    });

    if (resp) {
      const current = document.getElementById(dest);
      if (!current) {
        console.error("destination container does not exist", dest);
        return;
      }

      const replacement = resp.getElementById(dest);
      if (!replacement) {
        console.error("replacement container does not exist", dest);
        return;
      }

      current.replaceWith(replacement);
      loadImages(replacement);

      if (history) {
        const prevSrc = window.location.href;
        const prevParams = (typeof window.location.search === 'string') ? Object.fromEntries(new URLSearchParams(window.location.search)) : {};
        const prevState = { cause: 'turbo-frame:load', dest, src: prevSrc, params: prevParams };
        window.history.replaceState(prevState, '', prevSrc);

        const src = `${url.pathname}${objectToGetParams(params)}`;
        const state = { cause: 'turbo-frame:load', dest, src, params };
        window.history.pushState(state, '', src);
      }

      if (scrollTop) {
        scrollIntoView(replacement, true);
      } else if (btnName !== '' && scroll) {
        restoreScrollPosition(replacement, btnName, scroll);
      }

      window.dispatchEvent(new CustomEvent('turbo-frame:loaded', {
        detail: {
          oldFrame: current,
          newFrame: replacement,
        },
      }));
    }
  });

  window.addEventListener('turbo-frame:loaded', (e) => {
    const { oldFrame, newFrame } = e.detail;
    unbindButtons(oldFrame);
    bindButtons(newFrame);
  });

  window.addEventListener('popstate', (e) => {
    const { state } = e;
    if (!state || state.cause !== 'turbo-frame:load') {
      return;
    }

    const { dest, src, params } = state;
    window.dispatchEvent(new CustomEvent('turbo-frame:load', {
      detail: {
        dest,
        src,
        params,
      },
    }));
  });

  bindButtons(document);
};

export default ajaxFrame;
