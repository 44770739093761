import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'spin.js';

import 'spin.js/spin.css';
import './Loader.scss';

const spinnerOpts = {
  lines: 13,
  length: 0,
  radius: 24,
  width: 12,
  color: 'var(--primary-cta-color)',
};

const Animation = ({ className }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const spinner = new Spinner(spinnerOpts);

    if (containerRef && containerRef.current) {
      spinner.spin(containerRef.current);
    }

    return () => {
      spinner.stop();
    };
  }, [containerRef]);

  return (
    <div
      className={className}
      ref={containerRef}
    />
  );
};

Animation.propTypes = {
  className: PropTypes.string,
};

Animation.defaultProps = {
  className: undefined,
};

const Loader = ({
  className,
  loaded,
  children,
}) => !loaded ? (
  <Animation className={className} />
) : children ? (
  <div className={className}>
    {children}
  </div>
) : null;

Loader.propTypes = {
  className: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

Loader.defaultProps = {
  className: 'loader',
  loaded: false,
  children: null,
};

export default Loader;