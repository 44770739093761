import logger from './logger';

export function mountEnhancements(context) {
  const keys = Object.keys(context);

  for (const key of keys) {
    const module = context[key];

    if (module.default) {
      try {
        logger.debug(`Mounting enhancement '${key}'`);
        module.default();
      } catch (err) {
        logger.error(`Failed to mount enhancement '${key}'`, err);
      }
    }
  }
}
