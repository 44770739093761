import { trackEvent } from '../lib/analytics';
import { toTitleCase } from '../lib/utils';

const stickybar = () => {
  const elem = document.getElementById('stickybar');

  if (elem) {
    const campaign = toTitleCase(elem.dataset.campaign);
    const storageKey = `stickybar-${campaign}`.toLowerCase();
    const links = elem.querySelectorAll('a');
    const status = () => window.sessionStorage.getItem(storageKey) !== 'closed';
    const impression = () => {
      if (status()) {
        trackEvent({
          category: campaign,
          action: 'Impression',
        });
      }
    };

    // report the impression
    impression();

    // report any clicks
    links.forEach((el) => {
      const text = `${el.textContent}`.trim();

      el.addEventListener('click', () => trackEvent({
        category: campaign,
        action: 'Click',
        payload: {
          text,
        },
      }));
    });

    // close on click
    const closeStickybar = () => {
      window.sessionStorage.setItem(storageKey, 'closed');
      elem.classList.toggle('closed', true);

      trackEvent({
        category: campaign,
        action: 'Close',
      });
    };

    elem.querySelectorAll('button.stickybar--close').forEach((btn) => {
      btn.addEventListener('click', closeStickybar);
    });

    window.addEventListener('storage', (e) => {
      // only fires when session storage changes on a separate tab or dev console
      if (e.storageArea === window.sessionStorage && e.key === storageKey) {
        elem.classList.toggle('closed', !status());
        impression();
      }
    });
  }
};

export default stickybar;
